<template>
  <div>
    <van-cell-group title="主要信息">
      <van-field type="text" label="会议主题" v-model="model.TITLE" placeholder="请输入主题" />
      <van-field type="text" label="主持人" v-model="model.HOST" placeholder="请填写主持人" />
      <van-field type="textarea" label="会议内容" v-model="model.CONT" placeholder="会议主要内容" row="5" show-word-limit
        maxlength="1000" />
    </van-cell-group>
    <van-cell-group title="附加信息">
      <van-cell title="会议分类" v-model="model.CAT" @click="cat.show = true" is-link />
      <van-popup v-model="cat.show" round position="bottom">
        <van-picker :columns="cat.dat" show-toolbar @cancel="cat.show = false" @confirm="catConfirm" />
      </van-popup>
      <van-cell title="会议地点" v-model="model.LOC" @click="loc.show = true" is-link />
      <van-popup v-model="loc.show" round position="bottom">
        <van-picker :columns="loc.dat" show-toolbar @cancel="loc.show = false" @confirm="locConfirm" />
      </van-popup>
      <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" @click="time.show = true" is-link />
      <van-popup v-model="time.show" round position="bottom">
        <van-datetime-picker type="datetime" title="年/月/日/时/分" :min-date="time.minDate" v-model="time.curDate"
          @cancel="time.show = false" @confirm="timeConfirm" />
      </van-popup>
      <van-cell title="与会人员" v-model="model.CNT_JOIN" @click="tea.show = true" is-link />
      <van-popup v-model="tea.show" round position="bottom" closeable @close="countJoin">
        <div style="padding: 10px">
          <van-row>
            <van-checkbox v-model="tea.all" @change="teaCheckAll"><b>全选</b></van-checkbox>
            <van-divider />
          </van-row>
          <van-row>
            <van-collapse v-model="tea.activeName" v-for="it in tea.dat" :key="it.group" accordion>
              <van-collapse-item :title="it.group" :name="it.group">
                <!-- <van-col :span="8" style="margin: 10px 0">
                  <van-checkbox v-model="it.all" @click="teaCheckGroup(it)"><b>全部门</b></van-checkbox>
                </van-col> -->
                <van-col :span="8" v-for="tea in it.lst" :key="tea.ID" style="margin: 10px 0">
                  <van-checkbox :name="tea.ID" v-model="tea.checked">{{ tea.NAME }}</van-checkbox>
                </van-col>
              </van-collapse-item>
            </van-collapse>
          </van-row>
        </div>
      </van-popup>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting"
        loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import moment from "moment";
export default {
  name: "edit",
  data() {
    return {
      model: { CNT_JOIN: 0 },
      cat: {
        show: false,
        dat: []
      },
      loc: {
        show: false,
        dat: []
      },
      time: {
        show: false,
        minDate: moment().add(-1, 'y').toDate(),
        curDate: moment().toDate(),
      },
      tea: {
        show: false,
        dat: [],
        ret: [],
        all: false,
        activeName: []
      },
      submitting: false,
      lst: []
    }
  },
  created() {
    this.init();
  },
  methods: {
    // get() {
    //   let self = this;
    //   let g = this.$route.query.guid;
    //   if (g !== undefined) {
    //     this.whale.remote.getResult({
    //       url: "/api/Mobile/MEET/MinfoApi/Get",
    //       data: { GUID: g },
    //       completed(m) {
    //         self.tea.dat.map(o => {
    //           if (m.DATA.JOINS.indexOf(',' + o.ID + ',') >= 0) {
    //             //o.checked = true
    //             self.tea.ret.push(o.ID)
    //           }
    //         })
    //         self.model = m.DATA;
    //         console.log('455', self.model)
    //       }
    //     })
    //   }
    // },
    init() {
      let self = this;

      let g = this.$route.query.guid;
      if (g !== undefined) {
        this.whale.remote.getResult({
          url: "/api/Mobile/MEET/MinfoApi/Get",
          data: { GUID: g },
          completed(m) {
            self.tea.dat.map(o => {
              if (m.DATA.JOINS.indexOf(',' + o.ID + ',') >= 0) {
                //o.checked = true
                self.tea.ret.push(o.ID)
              }
            })
            self.lst = m.DATA.JOINS.split(',')
            self.model = m.DATA;
            self.getPeoPle()
          }
        })
      } else {
        self.getPeoPle()
      }

      this.whale.remote.getCollection({
        url: "/api/Mobile/FX/DictApi/GetList",
        data: { TYPE: "MEET" },
        completed(its) {
          its.ITEMS.map(o => {
            if (o.NAME == "MEET_CAT") self.cat.dat.push(o.VALUE)
            else if (o.NAME == "MEET_LOC") self.loc.dat.push(o.VALUE)
          })
          self.model.CAT = self.cat.dat[0];
          self.model.LOC = self.loc.dat[0];
        }
      })


    },
    getPeoPle() {
      let self = this;
      this.whale.remote.getCollection({
        url: '/api/Mobile/ORG/TeacherApi/GetList',
        completed(its) {
          let t = {}
          console.log(self.lst)
          if (its.ITEMS.length == self.lst.length) {
            self.tea.all = true
          }
          its.ITEMS.map(o => {
            console.log(self.lst.indexOf(o.ID + ''))
            if (self.lst.indexOf(o.ID + '') != -1) {
              o.checked = true
            } else {
              o.checked = false;
            }
          });
          console.log('123', its.ITEMS)
          its.ITEMS.map(o => {
            if (t[o.DEPT_NAME]) t[o.DEPT_NAME].push(o)
            else t[o.DEPT_NAME] = [o]
          });

          let arr = [];
          for (let o in t) {
            arr.push({
              group: o,
              lst: t[o],
              all: false
            })
          }
          console.log('arr', arr)
          self.tea.dat = arr;
        }
      })
    },
    catConfirm(val) {
      this.cat.show = false;
      this.model.CAT = val;
    },
    locConfirm(val) {
      this.loc.show = false;
      this.model.LOC = val;
    },
    timeConfirm(val) {
      this.time.show = false;
      console.log('val',val)
      this.model.TIME = this.$moment(val).format('YYYY-MM-DD HH:mm:ss');      
      console.log(' this.model.TIME', this.model.TIME)
    },
    teaCheckAll() {
      //this.$refs.cb_tea_all.toggleAll(this.tea.all);
      this.tea.dat.map(g => {
        g.all = this.tea.all;
        g.lst.map(o => {
          o.checked = this.tea.all
        })
      })
    },
    teaCheckGroup(g) {
      g.lst.map(o => {
        o.checked = g.all
      })
    },
    countJoin() {
      let n = 0;
      this.tea.ret = [];
      this.tea.dat.map(g => {
        g.lst.map(o => {
          if (o.checked) {
            this.tea.ret.push(o.ID)
            n++;
          }
        })
      })
      this.model.CNT_JOIN = n;
    },

    submit() {
      if (this.model.TIME == undefined) {
        Toast.fail("请选择开会时间")
        return
      }
      let self = this;
      this.model.JOINS = this.tea.ret.join(',');
      self.submitting = true;
      this.whale.remote.getResult({
        url: "/api/Mobile/MEET/MinfoApi/Save",
        data: self.model,
        finally() { self.submitting = false },
        completed() {
          self.$dialog.alert({
            message: "记录已成功提交！"
          }).then(() => {
            self.$router.go(-1);
          })
        }
      })
    }
  }
}
</script>
<style scoped></style>
